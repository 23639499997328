import axios from 'axios';
import type { Attachment } from '../schemas';
import { API_TYPE, post } from './fetch';
import {
  generateIndexedDBKey,
  // getIndexedDBBlobObject,
  getIndexedDBObject,
  storedIndexedDBObjectType,
  upsertIndexedDBObject,
} from './indexedDB';
import {
  extractQuestionIdAndSequence,
  extractTimestamp,
} from './extractQuestionIdAndSequenceTime';

type UploadParams = {
  dbfiles: Attachment[];
  files: File[];
  networkNumber: string;
  questionSequenceNumber: number | string;
  questionSetIdParam: string | null;
  jwtToken: string;
};

type UploadDeviationsParams = {
  files: File[];
  networkNumber: string;
  jwtToken: string;
};

type DownloadParams = {
  files: Attachment[];
  jwtToken: string;
};

type SignatureResponse = {
  signedUrl: string;
  originalFilename: string;
  locationFilename: string;
};

export enum FileType {
  ATTACHMENT,
  DEVIATION,
}

export const getDownloadSignedUrlForAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  //need to write code to convert tp blob andstpre in indexdb when downloaded image afterrefresh.. so that after immediate offline time we can show or pull image from indexdb
  return await post('v1/attachments/download', jwtToken, API_TYPE.APPLICATION, payload);
};

export const getDownloadSignedUrlForDeviationsAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post(
    'v1/deviations/files/downloads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForAttachment = async (
  params: UploadParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, questionSequenceNumber, questionSetIdParam, jwtToken } =
    params;
  const payload = {
    filenames: files.map((file) => file.name),
  };
  return await post(
    `v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForDeviationsAttachment = async (
  params: UploadDeviationsParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, jwtToken } = params;
  const payload = {
    filenames: files.map((file) => file.name),
    networkNumber,
  };
  return await post(
    'v1/deviations/files/uploads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

const matchAndUpdateDbFiles = (dbFiles: any[], base64data: any[]) => {
  const updatedDbFiles = dbFiles.map((dbFile) => ({ ...dbFile })); // Create a new array with the same data as dbFiles

  updatedDbFiles.forEach(async (dbFile) => {
    const locationData = extractQuestionIdAndSequence(dbFile.location);

    if (locationData) {
      const { questionId, sequenceNumber } = locationData;
      await base64data.forEach((entry) => {
        if (entry.questionId === questionId && entry.sequenceNumber === sequenceNumber) {
          return entry;
        }
      });
      // Find matching base64data entry
      const matchingBase64 = await base64data.find(
        (entry) =>
          entry.questionId === questionId && entry.sequenceNumber === sequenceNumber
      );

      // If a match is found, append the new data to the dbFile
      if (matchingBase64) {
        dbFile.questionId = questionId;
        dbFile.sequenceNumber = sequenceNumber;
        dbFile.base64Data = matchingBase64.base64Data;
        dbFile.mimeType = matchingBase64.mimeType; // Append the base64 data to the dbFile
        console.log(`Updated ${dbFile.filename} with base64data: ${dbFile.base64data}`);
      } else {
        console.log(`No matching base64data found for ${dbFile.filename}`);
      }
    } else {
      console.log(`Invalid location format for ${dbFile.filename}`);
    }
  });
};
export const convertBlobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = (reader.result as string).toString().split(',')[1];

      resolve(base64Data);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

export const uploadAttachments = async (
  params: UploadParams | UploadDeviationsParams,
  fileType = FileType.ATTACHMENT
): Promise<Attachment[] | undefined> => {
  const { dbfiles, files, networkNumber, questionSequenceNumber, questionSetIdParam } =
    params as UploadParams;
  const attachmentParam: Attachment[] = [];
  const indexDbattachmentParam: any[] = [];
  let uploadFiles: any = [];
  const base64IndexDbkey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.BASE64SSTRING
  );

  try {
    if (Array.isArray(files)) {
      const fileArray = files;
      const fileDataPromises = fileArray.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            if (file instanceof File) {
              if (reader.result) {
                const blob = new Blob([reader.result], { type: MimeType.toString() });
                try {
                  const base64Data = await convertBlobToBase64(blob);
                  resolve({
                    fileName: file.name,
                    base64Data: base64Data,
                    mimeType: MimeType.toString(),
                    questionSequenceNumber: questionSequenceNumber,
                    questionSetIdParam: questionSetIdParam,
                  });
                } catch (error) {
                  reject(error);
                }
              }
            }
          };
          reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
        });
      });
      Promise.all(fileDataPromises).then((filesData) => {
        uploadFiles = filesData;
      });
    }

    const responseSignedURLList =
      fileType === FileType.ATTACHMENT
        ? await getUploadSignedUrlForAttachment(params as UploadParams)
        : await getUploadSignedUrlForDeviationsAttachment(
            params as UploadDeviationsParams
          );
    if (navigator.onLine && responseSignedURLList && responseSignedURLList.length > 0) {
      await Promise.all(
        responseSignedURLList.map(async (response: SignatureResponse, index: number) => {
          console.log('files[index]', files[index]);
          const blob = new Blob([files[index]], { type: MimeType.toString() });
          const base64Data = await convertBlobToBase64(blob);
          const responseTimestamp = await extractTimestamp(response.locationFilename);
          attachmentParam.push({
            filename: response.originalFilename,
            location: response.locationFilename,
            timestamp: responseTimestamp,
          });
          indexDbattachmentParam.push({
            filename: response.originalFilename,
            location: response.locationFilename,
            timestamp: responseTimestamp,
            questionId: questionSetIdParam,
            sequenceNumber: questionSequenceNumber,
            base64Data: base64Data,
            mimeType: MimeType.toString(),
            tobeSynced: false,
          });

          return axios.put(`${response.signedUrl}`, files[index]);
        })
      );
      const base64dataArray: any[] = (await getIndexedDBObject(
        base64IndexDbkey
      )) as any[];
      await matchAndUpdateDbFiles(dbfiles, base64dataArray);
      const indexdata: any[] = (await getIndexedDBObject(base64IndexDbkey)) || [];
      indexdata.push(...indexDbattachmentParam);
      // Remove duplicate files from indexdata
      const uniqueIndexData = indexdata.filter((item, index, self) => {
        const foundIndex = self.findIndex(
          (i) =>
            i.filename === item.filename &&
            i.questionId === item.questionId &&
            i.sequenceNumber === item.sequenceNumber &&
            i.timestamp === item.timestamp
        );
        return foundIndex === index;
      });

      // Update indexdata with unique files
      await upsertIndexedDBObject(uniqueIndexData, base64IndexDbkey);
    } else {
      //if sync happens no need to have this else part as sync will handle offline case\
      if (uploadFiles) {
        await Promise.all(
          uploadFiles.map(async (fileData: any) => {
            attachmentParam.push({
              filename: fileData.fileName,
              location: `${networkNumber}/${questionSetIdParam}/${questionSequenceNumber}/${fileData.fileName}`,
              timestamp: new Date().toISOString(),
              toBeSynced: true,
            });
            indexDbattachmentParam.push({
              filename: fileData.fileName,
              location: `${networkNumber}/${questionSetIdParam}/${questionSequenceNumber}/${fileData.fileName}`,
              timestamp: attachmentParam[attachmentParam.length - 1]?.timestamp,
              questionId: questionSetIdParam,
              sequenceNumber: questionSequenceNumber,
              base64Data: fileData.base64Data,
              mimeType: MimeType.toString(),
              tobeSynced: true,
            });
          })
        );

        const base64dataArray: any[] = (await getIndexedDBObject(
          base64IndexDbkey
        )) as any[];
        await matchAndUpdateDbFiles(dbfiles, base64dataArray);
        const indexdata: any[] = (await getIndexedDBObject(base64IndexDbkey)) || [];
        indexdata.push(...indexDbattachmentParam);
        // Remove duplicate files from indexdata
        const uniqueIndexData = indexdata.filter((item, index, self) => {
          const foundIndex = self.findIndex(
            (i) =>
              i.filename === item.filename &&
              i.questionId === item.questionId &&
              i.sequenceNumber === item.sequenceNumber &&
              i.timestamp === item.timestamp
          );
          return foundIndex === index;
        });
        // Update indexdata with unique files
        await upsertIndexedDBObject(uniqueIndexData, base64IndexDbkey);
      }
    }
  } catch (e) {
    console.error('Failed to upload attachments', e);
  }
  return attachmentParam.length > 0 ? attachmentParam : undefined;
};
