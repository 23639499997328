export const extractQuestionIdAndSequence = (
  location: string
): { questionId: string; sequenceNumber: number } | null => {
  const regex = /([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)$/;
  const match = location.match(regex);

  if (match) {
    return {
      questionId: match[2], // Second part of the match (questionId)
      // Third part of the match (sequenceNumber)
      sequenceNumber: parseInt(match[3], 10),
    };
  } else {
    return null; // Return null if the location format is invalid
  }
};
export const extractTimestamp = (filename: string): string | undefined => {
  // Regular expression to match the timestamp pattern
  const regex = /(\d{4})_(\d{2})_(\d{2})T(\d{2})_(\d{2})_(\d{2})_(\d{3})Z/;
  const match = filename.match(regex);

  if (match) {
    // Extract the matched groups
    const year = match[1];
    const month = match[2];
    const day = match[3];
    const hour = match[4];
    const minute = match[5];
    const second = match[6];
    const millisecond = match[7];

    // Format the timestamp as '2024-11-14T05:24:42.287Z'
    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}Z`;
  }
};
